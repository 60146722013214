// <- FL TillReiners <- Kaffkiez <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN

@import './general';
@import './navigation';
@import './maki';
@import './nigiri';
@import './detail-pages';
@import './checkout';
@import './footer';
@import './g8';

// OTHER

.hero-slider {
  margin-bottom: 0;
}

.stage {
  //box-shadow: $box-shadow-xl;
  img {
    //filter: invert(1);
    transform: rotate(-2deg);
    width: 75%;
    @include media-breakpoint-up(md) {
      width: 50%;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }
}

body[data-scrolled] {
  .stage {
    opacity: 0.5;
    transition: all 0.2s ease;
    //transform: translateY(0);
  }
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  //backdrop-filter: none !important;
}

.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

.badge-100 {
  font-size: 100% !important;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}
