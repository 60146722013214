.nigiri.card {
  background-color: $gh_white;
  border: 0;
  box-shadow: $box-shadow-xl;
  font-family: $headings-font-family;
  transition: all 0.2s ease;

  // TBA
  &.tag_3369 {
    background-color: rgba($gh_white, 0.8);
    &::before {
      display: block;
      position: absolute;
      left: -5px;
      content: 'NACHHOLTERMIN';
      background-color: $gh_black;
      color: $gh_white;
      font-family: $headings-font-family;
      font-size: $h6-font-size;
      font-weight: 400;
      transform: rotate(-3deg);
      padding: 0.25rem 0.5rem;
      @extend .badge;
      box-shadow: $box-shadow;
    }
  }

  // Zusatzshow
  &.tag_5042 {
    &::before {
      display: block;
      position: absolute;
      left: -5px;
      content: 'NEUE SHOW';
      background-color: $gh_primary;
      color: $gh_white;
      font-family: $headings-font-family;
      font-size: $h5-font-size;
      font-weight: 900;
      transform: rotate(-3deg);
      padding: 0.25rem 0.5rem;
      @extend .badge;
      box-shadow: $box-shadow;
    }
  }

  .badge {
    font-size: 90%;
    right: 0;
    top: 0;
    &.sold-out-status_rescheduled {
      background-color: $warning;
      color: $black;
    }
    &.sold-out-status_starts_soon {
      background-color: $info;
      color: $white;
    }
    &.sold-out-status_sold_out.badge-danger {
      background-color: $gh_primary !important;
      color: $white !important;
    }
  }

  .nigiri-content {
    margin-top: 0.2rem;
    color: $gh_black;
    @include media-breakpoint-down(md) {
      display: table;
    }

    .sold-out-status_sold_out.badge-danger {
      transform: none;
      background-color: $gh_black;
      color: $gh_black;
      z-index: 2;
      font-size: 0.8em;
    }

    .nigiri-date {
      font-size: $h4-font-size;
      font-weight: 700;
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      //text-transform: uppercase;
      font-weight: 600;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .nigiri-cta .btn {
      color: $gh_white;
      background: $gh_primary;
    }
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    color: $gh_black;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gh_black;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gh_black;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-size: $h4-font-size !important;
    font-weight: 900;
    color: $gh_black;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease;
  }
}
