// Product detail page

.product-detail-page {
  background: $black url('./assets/images/bg_foil.png') center center;
  background-attachment: fixed;
  background-size: cover;
  #main {
    //background-color: rgba($gh_black, 0.9) !important;
    //backdrop-filter: blur(25px);
    background-color: transparent;
  }
  .product-content {
    //color: $white;
    background: transparent;
  }
  .price span {
    box-shadow: $box-shadow-inverse-xl;
    background: url('./assets/images/pricetag.svg') center center no-repeat;
    background-size: contain;
    padding: 1rem 1.5rem;
    display: inline-block;
    transform: rotate(-2deg);
  }
}

// Ticket Detail Page

.ticket-detail-page {
  .card {
    border: 0;
  }
  .blurry-background-image {
    background: linear-gradient(45deg, rgba(#3a1471, 0.5), transparent, rgba(#3a1471, 0.5)),
      linear-gradient(45deg, $gh_black, #2d2aa0, $gh_black);
  }
  .product-info-bar {
    //background: linear-gradient(45deg, rgba(#3a1471, 0.5), transparent, rgba(#3a1471, 0.5)), linear-gradient(45deg, $gh_black, #2d2aa0, $gh_black);
    background: rgba($gh_purple, 0.4);
    border-radius: 0;
    font-family: $headings-font-family;
    text-transform: uppercase;
    //a, .btn {color: $gh_white !important}
    i {
      color: $gh_primary !important;
    }
  }
  .card.article-title {
    background: linear-gradient(-45deg, rgba(#3a1471, 0.5), transparent, rgba(#3a1471, 0.5)),
      linear-gradient(-60deg, $gh_black, #2d2aa0, $gh_black);
    box-shadow: $box-shadow-xl;
    border-radius: 0;
    .super-title {
      color: $gh_white;
    }
    h1 {
      color: $gh_white;
      font-size: $h2-font-size;
      @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
      }
      small {
        color: $gh_white;
      }
    }
  }

  // TABS TICKET DETAIL

  article.ticket-detail {
    .DayPicker-Weekday {
      color: $black;
    }
    h2 {
      @extend .mb-0;
    }
    .nav-tabs {
      border-bottom: 2px solid $gh_secondary;
    }
    .nav-tabs .nav-link {
      color: $gh_secondary;
      border: 0;
      border-bottom: 1px solid $gh_secondary;
      @extend .py-3;
    }
    .nav-tabs .nav-link.active {
      background: $gh_secondary;
      color: $gh_white;
      border-bottom: 1px solid $gray-700;
      &:hover {
        //color: lighten($primary, 10%);
        background: rgba($gh_secondary, 0.8);
        color: $gh_white;
      }
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-bottom: 1px solid $gray-700;
      background: $gray-100;
      color: $gray-500;
    }
  }
}

// Product Detail Page

.detail-page {
  h1 {
    margin-top: 0;
    color: $gh_primary;
    //text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      color: $gh_grey;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
  a {
    color: $gh_primary;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.carousel {
  box-shadow: $box-shadow-xl;
}

.carousel-item-content {
  @extend .p-3;
  background: white;
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}
