#footer {
  box-shadow: $box-shadow-inverse-xl;
  min-height: 30vh;
  background: linear-gradient(45deg, rgba($gh_black, 0.5), transparent, rgba($gh_black, 0.5)),
    linear-gradient(45deg, $gh_black, $gh_secondary, $gh_black);
  color: $gh_white;
  line-height: 1.75rem;
  h5 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: $h3-font-size;
  }
  a {
    color: $gh_white;
    text-decoration: none;
    &:hover {
      background-color: transparent;
    }
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 1.5rem;
      filter: invert(1);
    }
    margin-top: 25px;
  }
}
