body {
  font-family: $gh_font;
  background-color: $gh_black;
  background: $black url('./assets/images/bg_foil.png') center center;
  //background: linear-gradient(rgba(#FFF, 0.8), rgba(#FFF, 0.9)), url('./assets/images/wednesday_back.jpg');
  background-attachment: fixed;
  background-size: cover;
  #main {
    //background-color: rgba($gh_black, 0.9) !important;
    //backdrop-filter: blur(25px);
    background-color: transparent;
  }
}

body.landing-page {
  //background: linear-gradient(rgba(#FFF, 0.8), rgba(#FFF, 0.9)), url('./assets/images/wednesday_back.jpg');
  //background-attachment: fixed;
  //background-position: center center;

  a.maki.card {
    //box-shadow: $box-shadow-xl;
    .card-title .title {
      //color: #031a58;
    }
    .price {
      background: url('./assets/images/pricetag_orange.svg') center center no-repeat !important;
      color: #FFF;
    }
  }
}

#root {
  padding-top: 0;
}

#main {
  background-color: $gh_white;
  //backdrop-filter: blur(10px);
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
  font-family: $headings-font-family;
}

.btn-primary {
}

.herohead {
  @include font-size(5rem);
  color: $black;
  transform: rotate(-2deg);
}

.btn {
}

::selection {
  background-color: $gh_primary;
  color: $gh_black;
}

a {
  color: $gh_black;
}

h1 {
  color: $gh_primary;
}

h1, h2, h3, h4, h5 {
  letter-spacing: 0.1rem;
}

#footer ul a,
#footer address a {
  &:hover {
    color: $gh_primary;
  }
}

body.product-listing-page,
body.ticket-listing-page {
  h1 {
    padding-bottom: 4rem !important;
  }
  background: $black url('./assets/images/bg_foil.png') center center;
  background-attachment: fixed;
  background-size: cover;
  #main {
    //background-color: rgba($gh_black, 0.9) !important;
    //backdrop-filter: blur(25px);
    background-color: transparent;
  }
}

.checkout .when-valid.is-valid,
form .when-valid.is-valid,
.shoppingcart-finisher {
  background: linear-gradient(45deg, rgba($gh_black, 0.5), transparent, rgba($gh_black, 0.5)),
    linear-gradient(45deg, $gh_black, $gh_secondary, $gh_black);
  box-shadow: $box-shadow-xl;
}

table.tt-listing {
  background-color: $white;
}
