body.g8-page {
  background-color: #000;

  background: $black url('./assets/images/bg_foil.png') center center;
  background-attachment: fixed;
  background-size: cover;

  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto !important;

  .g8-page-content {
    padding: 0px;
  }

  // .logo {
  // }

  // .logo:hover {
  //   filter: brightness(1.2);
  // }
  .main-wrapper {
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
  }
  .wait-wrapper .card {
    box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 90px;
    background-color: transparent;
    border: 0;
  }
  .countdown-wrapper .card {
    box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 90px;
    background-color: transparent;
    border: 0;
    font-variant-numeric: tabular-nums;
  }
  .go-wrapper .card {
    box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 90px;
    background-color: transparent;
    border: 0;
  }
  h1 {
    font-family: cy, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  .btn {
    font-family: cy, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 90px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .lead {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    text-size-adjust: 100%;
  }
  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    text-size-adjust: 100%;
    text-transform: uppercase;
  }
  div.text-center.small {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  body.g8-page {
    background-position-x: -50vw;
  }
}
