#header {
  box-shadow: $box-shadow-xl;
  background: $gh_secondary;
  background: linear-gradient(45deg, rgba($gh_black, 0.5), transparent, rgba($gh_black, 0.5)),
    linear-gradient(45deg, $gh_black, $gh_secondary, $gh_black);

  .item-count {
    background-color: $gh_primary;
  }

  .navbar {
    padding: 1rem;
    font-size: $h5-font-size;
    //background-color: $black !important;

    .nav-link {
      color: $gh_white;
      font-family: $headings-font-family;
      font-weight: 400;
      &:hover, &.active {
        color: $gh_primary;
      }
    }

    .navbar-brand {
      padding: 0;

      img {
        height: 2rem;
        max-height: 2rem;
        width: 25vw;
        background: url(./assets/images/logo_xs.svg);
        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(1);
        @include media-breakpoint-up(sm) {
          background: url(./assets/images/logo.svg);
          background-position: left center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 2rem;
          max-height: 2rem;
        }
        @include media-breakpoint-up(md) {
          background: url(./assets/images/logo.svg);
          background-position: left center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 2.5rem;
          max-height: 2.5rem;
        }
      }
    }

    .hamburger {
      color: $gh_white !important;
      z-index: 3;
      margin-top: 2px;
      @include media-breakpoint-down(sm) {
        //padding-left: 0px;
      }
    }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner:after,
    .hamburger.is-active .hamburger-inner:before {
      background-color: $gh_primary !important;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 2px;
    }

    .fal {
      font-weight: 500;
    }
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

// hide cart countdown on tiny displays
// .navbar .nav-link .badge.expiration {
//   display: none;
//   @media only screen and (min-width: 350px) {
//     display: inherit;
//   }
// }

.offcanvas-collapse {
  .navbar-nav .nav-item {
    font-family: $headings-font-family;
    margin-bottom: 0.5rem;
    background-color: rgba($color: $gh_black, $alpha: 0.8) !important;
    // font-size: $h2-font-size;
    color: $gh_black !important;
    // text-transform: uppercase;
    //box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse-left {
  background: transparent !important;
}

.offcanvas-collapse-right {
  .cart-listing {
    background: rgba(255, 255, 255);
  }
  // .coupon-container button:hover {
  //   color: white;
  // }
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}
