.masonry {
  row-gap: 2rem;
}

a.maki.card {
  @extend .p-2;
  transition: all 0.2s ease;
  background: $gh_white;
  box-shadow: $box-shadow;
  .card-body {
    overflow: hidden;
  }
  .card-title {
    text-align: center;
    justify-content: start;
    @extend .text-truncate;
    .title {
      //text-transform: uppercase;
      font-size: $h4-font-size;
      font-family: $headings-font-family;
      color: $gh_primary;
      letter-spacing: 0.05rem;
      @extend .text-truncate;
    }
    .sub-title {
      color: $gh_grey;
      letter-spacing: 0;
    }
    .price {
      color: $gh_black;
      @extend .mt-2;
      position: absolute;
      font-weight: bold;
      transform: rotate(3deg);
      right: 1rem;
      bottom: 6rem;
      box-shadow: $box-shadow-inverse-xl;
      background: url('./assets/images/pricetag.svg') center center no-repeat;
      background-size: contain;
      padding: 1rem 1.5rem;
    }
  }
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease;
    img {
      background-color: $white;
      filter: brightness(1.1);
      transition: all 0.2s ease;
    }
  }

  .maki-img-hover {
    margin: 0.5rem !important;
  }
}
// hover effect
a.maki.card {
  // border: 2px solid transparent !important;
  // border-width: 2px !important;
  // border-color: transparent !important;
  // border-style: solid;
  // border-radius: 4px !important;
  &:hover {
    box-shadow: $box-shadow-xl;
    //border-color: rgba($color: $gh_primary, $alpha: 0.3) !important;
    transition: all 0.15s ease-in-out;
  }
}
